import { Text, View } from '@react-pdf/renderer';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Included } from 'components/CheckYourDetailsPdf/typography';
import { ProductFeature } from 'components/QuoteSummary/ProductFeatureTable';

type ProductFeaturesTablePdfProps = {
  features: ProductFeature[];
  substitutePlaceholders: (csPlainTextString: string) => string;
  theme: DefaultTheme;
};

const ProductFeaturesTablePdf: React.FC<ProductFeaturesTablePdfProps> = ({
  features,
  substitutePlaceholders,
  theme,
}) => (
  <>
    <Section>
      <View style={{ marginRight: 70 }}>
        {features.map((feature) => (
          <Fragment key={feature.feature_text}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
              wrap={false}>
              <Included />
              <Text style={{ flex: 1 }}>
                {substitutePlaceholders(feature.feature_text.replace(/\|/g, ''))}
              </Text>
            </View>
            <SimpleDivider theme={theme} />
          </Fragment>
        ))}
      </View>
    </Section>
  </>
);

export default ProductFeaturesTablePdf;
