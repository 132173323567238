import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ProductFeature } from 'components/QuoteSummary/ProductFeatureTable';
import ProductFeaturesTablePdf from './ProductFeaturesTablePdf';
import { Heading2 } from '../typography';

export type ProductFeatureSectionProps = {
  sectionHeading: string;
  features: ProductFeature[];
  substitutePlaceholders: (csPlainTextString: string) => string;
  theme: DefaultTheme;
};

const ProductFeatureSection: React.FC<ProductFeatureSectionProps> = ({
  sectionHeading,
  features,
  substitutePlaceholders,
  theme,
}) => (
  <>
    <Section>
      <Heading2>{sectionHeading}</Heading2>
      <SimpleDivider theme={theme} />
      <ProductFeaturesTablePdf
        features={features}
        substitutePlaceholders={substitutePlaceholders}
        theme={theme}
      />
    </Section>
  </>
);

export default ProductFeatureSection;
