import { Polygon, Svg, Text } from '@react-pdf/renderer';
import React from 'react';

// medium heading
export const Heading1: React.FC = ({ children }) => (
  <Text
    style={{
      fontSize: 40,
      fontWeight: 'bold',
      lineHeight: 1.5,
    }}>
    {children}
  </Text>
);

// small heading
export const Heading2: React.FC = ({ children }) => (
  <Text
    style={{
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
    }}>
    {children}
  </Text>
);

export const Included: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Svg width="16" height="16" viewBox="0 0 24 24" style={{ marginRight: 5 }}>
    <Polygon
      points="16.2824,8 10.1236,14.5825 7.68838,12.195 7,12.8884 10.1542,16 17,8.66259"
      fill="#000000"
      strokeWidth="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    {children}
  </Svg>
);
